var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, gridTemplateColumns, gridGap, space, themeGet, fontWeight, } from 'styled-system';
import OfferTeaserItem from './OfferTeaserItem';
import RichTextBlock, { font } from '../../common/components/RichText';
var LAST_TEASER_INDEX = 2;
var TeaserGrid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, gridGap, gridTemplateColumns);
var teaserGridStyles = {
    display: 'grid',
    gridGap: ['sp_16', 'sp_24', null, 'sp_32'],
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
};
var Title = styled(RichTextBlock)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  p {\n    color: ", ";\n    ", ";\n    ", ";\n  }\n  ", ";\n"], ["\n  p {\n    color: ", ";\n    ", ";\n    ", ";\n  }\n  ", ";\n"])), themeGet('colors.sys.neutral.text.strong'), font(['fs_16', 'fs_18', null, 'fs_20'], ['lh_20', 'lh_24', null, 'lh_28']), fontWeight, space);
var titleStyles = {
    mb: ['sp_16', 'sp_20', 'sp_24', 'sp_32'],
    fontWeight: 'bold',
};
var OfferTeaser = function (_a) {
    var items = _a.items, titleText = _a.titleText, isTitleCenterAligned = _a.isTitleCenterAligned;
    return (React.createElement(React.Fragment, null,
        titleText && (React.createElement(Title, __assign({}, titleStyles, (isTitleCenterAligned && { textAlign: 'center' }), { dangerouslySetInnerHTML: { __html: titleText } }))),
        React.createElement(TeaserGrid, __assign({}, teaserGridStyles), items.map(function (item, index) { return (React.createElement(OfferTeaserItem, __assign({}, item, { isEnhanced: index === LAST_TEASER_INDEX, index: index, key: "offer-teaser-item-".concat(index) }))); }))));
};
export default OfferTeaser;
var templateObject_1, templateObject_2;
