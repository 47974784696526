var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, space, margin, fontSize, maxWidth, height, color, textAlign, lineHeight, overflow, themeGet, position, } from 'styled-system';
import RichTextBlock from '../../common/components/RichText';
import { themeTop, themeLeft, translate3d, } from '../../common/theme/system-utilities';
var StoererContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  background-color: rgba(255, 255, 255, 0.85);\n  border-top: 2px solid\n    ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  background-color: rgba(255, 255, 255, 0.85);\n  border-top: 2px solid\n    ", ";\n"])), display, space, height, textAlign, position, margin, themeTop, themeLeft, color, translate3d, function (props) {
    return props.borderColor
        ? props.borderColor
        : themeGet('colors.sys.primary.background.default');
});
StoererContainer.defaultProps = {
    display: 'inline-table',
    px: '16px',
    margin: ['auto', 0],
    height: '28px',
    textAlign: 'center',
    color: 'sys.neutral.border.active',
};
var StyledRichTextBlock = styled(RichTextBlock)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  white-space: nowrap;\n  text-overflow: clip;\n\n  & p {\n    ", ";\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  white-space: nowrap;\n  text-overflow: clip;\n\n  & p {\n    ", ";\n    ", ";\n  }\n"])), display, maxWidth, overflow, fontSize, lineHeight);
StyledRichTextBlock.defaultProps = {
    display: 'inline-flex',
    fontSize: [4, null, null, 5],
    lineHeight: '28px',
    maxWidth: '138px',
    overflow: 'hidden',
};
export var Stoerer = function (_a) {
    var html = _a.label, containerProps = __rest(_a, ["label"]);
    return (React.createElement(StoererContainer, __assign({}, containerProps),
        React.createElement(StyledRichTextBlock, { "data-testid": "rich-text-block", dangerouslySetInnerHTML: { __html: html } })));
};
Stoerer.defaultProps = {
    borderColor: null,
    themeTop: 0,
    themeLeft: ['50%', 0],
    position: 'absolute',
    translate3d: 0,
};
var templateObject_1, templateObject_2;
