var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { backgroundColor, border, borderColor, borderStyle, borderWidth, boxShadow, color, display, fontSize, gridColumn, height, lineHeight, position, right, space, width, } from 'styled-system';
import { Button, ButtonBase } from '../../common/components/Button';
import { Flex } from '../../common/components/Flex';
import { ResponsiveLazyImage } from '../../common/components/LazyImage';
import { Link } from '../../common/components/Link';
import { Heading, Text } from '../../common/components/Text';
import { useOpenDisclaimer } from '../../common/hooks/drawer';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import { useIsObserved } from '../../common/hooks/intersection-observer';
import { textDecoration, themeBottom, whiteSpace, } from '../../common/theme/system-utilities';
import { Stoerer } from './Stoerer';
var IMAGE_HEIGHT = ['168px', null, '176px'];
var ENHANCED_IMAGE_HEIGHT = ['168px', '214px', '252px', '176px'];
var ENHANCED_CONTENT = {
    alignItems: ['center', null, null, 'flex-start'],
    flexDirection: ['column', 'row', null, 'column'],
    justifyContent: ['center', 'space-between', null, 'center'],
};
var TeaserItem = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), backgroundColor, gridColumn);
TeaserItem.defaultProps = {
    backgroundColor: 'sys.neutral.background.default',
};
var StyledLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n  ", ";\n"])), borderStyle, borderWidth, borderColor, border, boxShadow, width);
StyledLink.defaultProps = {
    width: 1,
    borderStyle: [null, 'solid'],
    borderWidth: [null, 'small'],
    borderColor: [null, 'sys.neutral.border.inactive'],
    boxShadow: ['0 2px 6px 0 rgba(192, 192, 192, 0.5)', 'none'],
};
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), position);
ImageContainer.defaultProps = {
    position: 'relative',
};
var Image = styled(ResponsiveLazyImage)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  object-fit: cover;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  object-fit: cover;\n"])), width, height, display);
Image.defaultProps = {
    display: 'block',
    width: 1,
    height: IMAGE_HEIGHT,
};
var Content = styled(Flex)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
Content.defaultProps = {
    px: [null, 'sp_24', null, 'sp_32'],
    py: ['sp_16', 'sp_24', 'sp_20'],
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: ['center', 'flex-start'],
};
var TextContainer = styled(Flex)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
TextContainer.defaultProps = {
    flexDirection: 'column',
    alignItems: ['center', 'normal'],
};
var StyledHeading = styled(Heading)(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
StyledHeading.defaultProps = __assign(__assign({}, Heading.defaultProps), { fontSize: ['fs_24', null, null, 'fs_28'], lineHeight: ['lh_28', null, null, 'lh_32'], textAlign: ['center', 'left'] });
var StyledText = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
StyledText.defaultProps = __assign(__assign({}, Text.defaultProps), { fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_24', null, null, 'lh_28'], textAlign: ['center', 'left'] });
var CtaButton = styled(Button)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, fontSize, lineHeight, whiteSpace);
CtaButton.defaultProps = __assign(__assign({}, Button.defaultProps), { kind: 'secondary', fontSize: 'fs_16', lineHeight: 'lh_24', mt: 'sp_12', px: 'sp_24', py: 'sp_8', whiteSpace: 'nowrap' });
var LegalDisclaimerButton = styled(ButtonBase)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, position, right, themeBottom, textDecoration);
LegalDisclaimerButton.defaultProps = __assign(__assign({}, ButtonBase.defaultProps), { position: 'absolute', right: 0, themeBottom: ['sp_4', null, null, 'sp_8'], px: 'sp_8', color: 'sys.neutral.border.active', fontSize: 'fs_12', textDecoration: 'underline' });
// eslint-disable-next-line complexity
var OfferTeaserItem = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, image = _a.image, enhancedImage = _a.enhancedImage, ctaButtonLabel = _a.ctaButtonLabel, ctaButtonLink = _a.ctaButtonLink, stoererLabel = _a.stoererLabel, stoererBorderColor = _a.stoererBorderColor, legalDisclaimerLinkLabel = _a.legalDisclaimerLinkLabel, legalDisclaimerLinkColor = _a.legalDisclaimerLinkColor, legalDisclaimerContent = _a.legalDisclaimerContent, tracking = _a.tracking, index = _a.index, isEnhanced = _a.isEnhanced;
    var teaserImage = isEnhanced && enhancedImage ? enhancedImage : image;
    var withDisclaimer = legalDisclaimerLinkLabel && legalDisclaimerContent;
    var openLegalDisclaimerDrawer = useOpenDisclaimer({ html: legalDisclaimerContent }, {
        preventDefault: true,
        stopPropagation: true,
    });
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    var _c = usePromoEvents(__assign(__assign({}, tracking), { position: index })), onPromoView = _c.onPromoView, onPromoClick = _c.onPromoClick;
    useEffect(function () {
        if (isObserved) {
            onPromoView();
        }
    }, [isObserved]);
    return (React.createElement(TeaserItem, __assign({ ref: ref }, (isEnhanced && { gridColumn: [1, 'span 2', null, 3] })),
        React.createElement(StyledLink, { "data-prefetch": true, href: ctaButtonLink.fullUrl, onClick: onPromoClick },
            React.createElement(ImageContainer, null,
                stoererLabel && (React.createElement(Stoerer, __assign({ label: stoererLabel, themeTop: [0, 'sp_16', 'sp_24'], translate3d: ['-50%, 0, 0', '0, 0, 0'] }, (stoererBorderColor && { borderColor: stoererBorderColor })))),
                React.createElement(Image, __assign({ alt: teaserImage === null || teaserImage === void 0 ? void 0 : teaserImage.alt }, teaserImage, (isEnhanced && { height: ENHANCED_IMAGE_HEIGHT }))),
                withDisclaimer && (React.createElement(LegalDisclaimerButton, __assign({ onClick: legalDisclaimerContent ? openLegalDisclaimerDrawer : null }, (legalDisclaimerLinkColor && {
                    color: legalDisclaimerLinkColor,
                })),
                    "*",
                    legalDisclaimerLinkLabel))),
            React.createElement(Content, __assign({}, (isEnhanced && ENHANCED_CONTENT)),
                React.createElement(TextContainer, __assign({}, (isEnhanced && {
                    flexDirection: ['column', null, 'row', 'column'],
                    alignItems: ['center', 'normal', 'baseline', 'normal'],
                })),
                    React.createElement(StyledHeading, null, title),
                    subtitle && (React.createElement(StyledText, __assign({}, (isEnhanced && { ml: [null, null, 'sp_8', 'sp_0'] })), subtitle))),
                React.createElement(CtaButton, __assign({}, (isEnhanced && { mt: ['sp_12', null, 'sp_0', 'sp_12'] })), ctaButtonLabel)))));
};
export default OfferTeaserItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
